import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Injectable({
  providedIn: 'root',
})

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss'],
})

export class PagerComponent implements OnInit {
  @Input() ruta: string = '';
  @Input() actualPage: number = undefined;
  @Input() set totalPages(value){
    if(value){
      this.totalPagesValue = value;
      this.actualPage && (this.actualPage = Number(this.actualPage));
      this.comprobeActualPage();
    }
  };
  @Output() upPage = new EventEmitter();
  pages = [1];
  btnSelected: HTMLIonButtonElement;
  totalPagesValue: number = 1;

  constructor() {}

  ngOnInit() {

    
  }

  comprobeActualPage() {
    setTimeout(async () => {
      this.setPages(this.totalPagesValue);
    }, 100);
  }

  setPages(totalPagesValue = 1) {    
    this.pages = [1];
    for (let i = 1; i < totalPagesValue; i++) {
      this.pages.push(i + 1);
    }
  }


  changePage(numPage?, page?) {    
    if(numPage === '...') return;
    let toPage;
    if (page !== undefined) {
      toPage = page;
      this.actualPage = toPage;
    } else {
      toPage = numPage;
      this.actualPage = toPage;
    }
    
    this.upPage.emit(this.actualPage);
  }

  changeWithBtn(direction: string) {
    let dct = Number(this.actualPage);
    if (direction === 'next') {
      dct++;

      return dct > this.totalPagesValue
        ? null
        : (this.changePage(null, dct), this.upPage.emit(dct));
    } else {
      dct--;
      return dct === 0
        ? null
        : (this.changePage(null, dct), this.upPage.emit(dct));
    }
  }
}
